export default [
  {
    name: 'infant',
    displayName: 'Infant',
    image: 'infant.jpg',
    message: '',
    ageStart: 0,
    ageEnd: 18,
    rates: [
      {
        type: 'Full Day',
        fullTime: 6800,
      },
    ],
  },
  {
    name: 'toddler',
    displayName: 'Toddler',
    image: 'toddler.jpg',
    ageStart: 18,
    ageEnd: 30,
    rates: [
      {
        type: 'Full Day',
        fullTime: 4685,
        partTime: 4985,
      },
    ],
  },
  {
    name: 'preschool',
    displayName: 'Preschool',
    image: 'preschool.jpg',
    ageStart: 30,
    ageEnd: 44,
    rates: [
      {
        type: 'Full Day',
        fullTime: 3715,
        partTime: 3970,
      },
    ],
  },
  {
    name: 'kindergarten',
    displayName: 'Kindergarten',
    image: 'kindergarten.jpg',
    ageStart: 44,
    ageEnd: 60,
    rates: [
      {
        type: 'Full Day',
        fullTime: 4725,
        partTime: 5205,
      },
    ],
  },
  {
    name: 'schoolAge',
    displayName: 'School Age',
    image: 'school-age.jpg',
    ageStart: 61,
    ageEnd: 144,
    rates: [
      {
        type: 'Full Day',
        fullTime: 4605,
        partTime: 5075,
      },
    ],
  },
]
