import * as Yup from 'yup'
import { isTooOld, isPreschool, isSchoolAge } from '../services/childService'
import { arrToObj, validDates } from '../utils'

/* USER */
export const loginInitialValues = {
  botField: '',
  email: '',
}

export const loginValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
})

/* GUARDIAN  */
export const guardianInitialValues = {
  botField: '',
  guardian: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    birthday: '',
    address: {
      formattedAddress: '',
      coordinates: '',
      street: '',
      unit: '',
      city: '',
      province: 'ON',
      postal: '',
    },
    // usedEmergencyCare: false,
    // umbrellaEmployee: false,
    // worksOutsideHome: false,
    subscribe: false,
  },
}

export const guardianValidationSchema = Yup.object({
  guardian: Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone: Yup.string()
      .matches(/\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/, 'Invalid phone number')
      .required('Required'),
    birthday: Yup.string()
      .matches(/(\d{4})-(\d{2})-(\d{2})/, 'Please enter date in YYYY-MM-DD format')
      .required('Required')
      .test('tooYoung', 'Please confirm your birthday, you seem too young', function (item) {
        const d = new Date().getTime()
        const udate = new Date(item).getTime()
        const dateOffset = 1000 * 60 * 60 * 24 * 365 * 18 // Person is at least 18 years old
        return udate < d - dateOffset
      })
      .test('tooOld', 'Please confirm your birthday, you seem too old', function (item) {
        const d = new Date().getTime()
        const udate = new Date(item).getTime()
        const dateOffset = 1000 * 60 * 60 * 24 * 365 * 90 // Person is less than 90 years old. Probably overkill
        return udate > d - dateOffset
      }),

    address: Yup.object({
      street: Yup.string().required('Please provide your address'),
      apt: Yup.string(),
      city: Yup.string().required('Required'),
      province: Yup.string().oneOf(['Ontario', 'ON'], 'Province should be Ontario').required('Required'),
      postal: Yup.string()
        .matches(/^([A-Za-z]\d[A-Za-z][- ]?\d[A-Za-z]\d)/, 'Invalid Postal Code')
        .required('Required'),
    }),
    subscribe: Yup.boolean(),
  }),
})

export const childInitialValues = {
  firstName: '',
  lastName: '',
  birthday: '',
  currentlyEnrolled: '',
}

export const childValidationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthday: Yup.string()
    .matches(/(\d{4})-(\d{2})-(\d{2})/, 'Please enter date in YYYY-MM-DD format')
    .required('Valid date required')
    .test(
      'tooOld',
      "Please confirm your child's birthday, they seem to be too old for our programming",
      function (item) {
        const udate = new Date(item).getTime()
        const dateOffset = isTooOld()
        return udate > dateOffset
      }
    ),
  // ageGroup: Yup.string().required('Please select a program type'),
  currentlyEnrolled: Yup.boolean().required('Required'),
})

export const enrollInitialValues = program => {
  const initialValues = {
    child: '',
    firstName: '',
    lastName: '',
    birthday: '',
    program,
    programTypes: '',
    ageGroup: '',
    attendance: {
      startWeek: '',
    },
  }
  // if (program === 'regular-programming') {
  //   initialValues.attendance = { startWeek: '' }
  // }

  return initialValues
}

export const enrollValidationSchema = program => {
  let validationObject = {
    child: Yup.string().required('Required'),
    program: Yup.string().required('Program Required'),
    ageGroup: Yup.string().required('Please select a program age group'),
  }

  // Start week input only applies to regular programming
  if (program === 'regular-programming') {
    validationObject = {
      ...validationObject,
      attendance: Yup.object({
        startWeek: Yup.string().required('Please choose a start week'),
      }),
    }
  } else {
    validationObject = {
      ...validationObject,
      attendance: Yup.object({
        startWeek: Yup.string(),
      }),
    }
  }

  return Yup.object(validationObject)
}

export const facilityChoiceInitialValues = {
  searchAddress: {
    formattedAddress: '',
    coordinates: '',
  },
  firstFacilityChoice: '',
  secondFacilityChoice: '',
}

export const facilityChoiceValidationSchema = Yup.object({
  firstFacilityChoice: Yup.string().required('Required'),
  secondFacilityChoice: Yup.string()
    .notOneOf([Yup.ref('firstFacilityChoice')], 'Your second choice should be different than the first')
    .required('Required'),
})

export const attendanceInitialValues = (child, program) => {
  console.groupCollapsed('FORMS: attendanceInitialValues')
  // console.log('Current Program', program)
  // console.log('Current Child', child)

  let initialValues = {}

  // Do we already have a child stored? Should move this to context hook
  // if (Object.entries(child).length !== 0) return initialValues

  // TODO
  // Used for regular programming
  if (program.ui === 'startWeek') {
    if (isPreschool(child.ageGroup)) {
      initialValues = {
        ...initialValues,
        fullday: {
          Mo: false,
          Tu: false,
          We: false,
          Th: false,
          Fr: false,
        },
      }
    }

    if (isSchoolAge(child.ageGroup)) {
      initialValues = {
        ...initialValues,
        before: {
          Mo: false,
          Tu: false,
          We: false,
          Th: false,
          Fr: false,
        },
        after: {
          Mo: false,
          Tu: false,
          We: false,
          Th: false,
          Fr: false,
        },
      }
    }

    initialValues = { ...initialValues, ...child.attendance }
  }

  // Used for summer camp
  if (program.ui === 'week') {
    initialValues = {
      ...initialValues,
      // ...arrToObj(validDates(program.weeks), false, 'partTime_'), // We need to include the part time checkbox to validate if at least one day is selected
      ...arrToObj(validDates(program.weeks), { mo: false, tu: false, we: false, th: false, fr: false }), // False means unchecked by default
    }

    initialValues = { ...initialValues, ...child.attendance }
  }

  // Used for breaks and PA Days
  if (program.ui === 'day') {
    initialValues = {
      ...initialValues,
      ...arrToObj(validDates(program.days), false), // False means unchecked by default
    }
    initialValues = { ...initialValues, ...child.attendance }
  }
  console.log('Initial Values', initialValues)
  console.groupEnd()

  return initialValues
}

export const attendanceValidationSchema = (child, program) => {
  console.groupCollapsed('FORMS: attendanceValidationSchema')
  let validationObject = {}

  // TODO
  if (child.program === 'regular-programming') {
    if (isPreschool(child.ageGroup)) {
      // Toddler/preschool Validation
      validationObject = {
        ...validationObject,
        fullday: Yup.object({
          Mo: Yup.boolean(),
          Tu: Yup.boolean(),
          We: Yup.boolean(),
          Th: Yup.boolean(),
          Fr: Yup.boolean(),
        }),
        atLeastOne: Yup.boolean().test('atLeastOne', 'Please choose at least one day of the week', function () {
          const values = Object.values(this.parent.fullday)
          return values.some(el => !!el) // This saved me. Didn't know about array.some
        }),
      }
    }

    if (isSchoolAge(child.ageGroup)) {
      // Toddler/preschool Validation
      validationObject = {
        ...validationObject,
        before: Yup.object({
          Mo: Yup.boolean(),
          Tu: Yup.boolean(),
          We: Yup.boolean(),
          Th: Yup.boolean(),
          Fr: Yup.boolean(),
        }),
        after: Yup.object({
          Mo: Yup.boolean(),
          Tu: Yup.boolean(),
          We: Yup.boolean(),
          Th: Yup.boolean(),
          Fr: Yup.boolean(),
        }),
        atLeastOne: Yup.boolean().test(
          'atLeastOne',
          'Please choose at least one day of the week in either before or after',
          function () {
            const values = [...Object.values(this.parent.before), ...Object.values(this.parent.after)]
            return values.some(el => !!el) // This saved me. Didn't know about array.some
          }
        ),
      }
    }
  }

  if (program.ui === 'week') {
    validationObject = {
      ...validationObject,
      atLeastOne: Yup.boolean().test('atLeastOne', 'Please choose attendance for at least one week', function () {
        // This functon pulls all booleans from the week objects and then checks if at least 1 is true
        let allweeksBools = []
        validDates(program.weeks).forEach(week => {
          allweeksBools = [...allweeksBools, ...Object.values(this.parent[week])]
        })
        return allweeksBools.some(el => !!el) // This saved me. Didn't know about array.some
      }),
    }
  }

  if (program.ui === 'day') {
    // Toddler/preschool Validation
    validationObject = {
      ...validationObject,
      ...arrToObj(validDates(program.days), Yup.boolean()), // Yup validation rules go here

      atLeastOne: Yup.boolean().test('atLeastOne', 'Please choose at least one day', function () {
        const values = Object.values(this.parent)
        return values.some(el => !!el) // This saved me. Didn't know about array.some
      }),
    }
  }

  console.log('Validation Object', validationObject)
  console.groupEnd()

  return Yup.object(validationObject)
}
