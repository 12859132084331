// Misc
import { isValid, parseISO, differenceInMonths, subMonths } from 'date-fns'

import ageGroups from '../data/ageGroups'
import attendanceData from '../data/attendanceDates'

// Number helpers
const roundTo = require('round-to')

/**
 * Determine the smallest amount of months old is needed for for program enrollment
 *
 * @return  {int}
 */
export const isOldEnough = () => subMonths(parseISO(attendanceData[0].start), ageGroups[0].ageStart)

/**
 * Determine the largest amount of months old is needed for for program enrollment
 *
 * @return  {int}
 */
export const isTooOld = () => subMonths(new Date(), ageGroups[ageGroups.length - 1].ageEnd + 1).getTime()

/**
 * Pick a program by name
 *
 * @var {[type]}
 */
// export const pickAgeGroup = (program = '') => ageGroups.find(p => p.name.toLowerCase() === program.toLowerCase())
export const pickAgeGroup = (program = '') => ageGroups.find(p => p.name === program)

/**
 * Pick a program image by comparing the image base name to the program name
 *
 * @var {obj}
 */
export const pickAgeGroupImage = (images, program) => images.find(image => image.base === program.image)

/**
 * [isSchoolAge description]
 *
 * @var {str}
 *
 * @return  {bool}
 */
export const isInfant = ageGroup => ageGroup === 'infant'
export const isPreschool = ageGroup => ageGroup === 'toddler' || ageGroup === 'preschool'
export const isSchoolAge = ageGroup => ageGroup === 'kindergarten' || ageGroup === 'schoolAge'

/**
 * Get the age groups from the data
 *
 * @var {str}
 *
 * @return  {bool}
 */
export const ageGroupsNames = ageGroups.map(ag => ag.name)
export const ageGroupsDisplayNames = ageGroups.map(ag => ag.displayName)
// export const getProgramsTypes = ageGroups

/**
 * Pick the child care program based on the supplied birthday
 *
 * @var {Date}
 */
export const pickAgeGroupByBirthday = birthday => {
  if (!isValid(birthday)) {
    console.log('Birthday is invalid')
    return false
  }

  const difference = differenceInMonths(birthday, parseISO(attendanceData[0].start)) // <-- This date needs to be set to the beginning of the schedule
  const selectedProgram = ageGroups.find(program => -difference >= program.ageStart && -difference <= program.ageEnd)

  return selectedProgram
}

/**
 * Takes an start and end age ( in months ) and converts it into a nice string
 *
 * @param   {int}  ageStart
 * @param   {int}  ageEnd
 *
 * @return  {str}            nicely formatted string
 */
export const formatAgeRange = ({ ageStart, ageEnd }) => {
  const start = ageStart > 42 ? `${roundTo.down(ageStart / 12, 1)} years` : `${ageStart} months`
  const end = ageEnd < 42 ? `${ageEnd} months` : `${roundTo.down(ageEnd / 12, 1)} years`

  let str = `${start} - ${end}`

  let test = (str.match(new RegExp('months', 'gi')) || []).length
  if (test > 1) {
    str = str.replace('months', '')
  }
  test = (str.match(new RegExp('years', 'gi')) || []).length
  if (test > 1) {
    str = str.replace('years', '')
  }

  return str
}

/**
 * Takes a dollar amount in cents and returns the proper international number format with the $ sign
 *
 * @var {int}
 *
 * @return  {string}
 */
export const formatRate = rate =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(rate / 100)
